import React, { useState, useEffect, useContext, useCallback } from 'react'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import Loader from 'react-loader-spinner'
import { loadStripe } from '@stripe/stripe-js'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useQueryParam, StringParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import UserContext from 'context/user/UserContext'
import { isMobilePlatform } from 'components/common/DeviceOS'
import {
  GET_SUBSCRIPTION_INFO,
  CREATE_SUBSCRIPTION_SESSION,
} from 'services/graphql'
import mq from 'styles/breakpoints'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const MEET3D_PREMIUM_MONTHLY_NAME =
  process.env.GATSBY_3DMEET_TIER_THREE_MONTHLY_NAME
const MEET3D_PREMIUM_ANNUALLY_NAME =
  process.env.GATSBY_3DMEET_TIER_THREE_ANNUALLY_NAME
const TIER_FREE = process.env.GATSBY_3DMEET_TIER_ONE_NAME
const TIER_PRO = process.env.GATSBY_3DMEET_TIER_TWO_NAME
const TIER_PREMIUM = process.env.GATSBY_3DMEET_TIER_THREE_NAME
const QUERY_PARAM_SUBSCRIPTION_NAME = 'subscription-name'

const SectionContainer = styled.div`
  position: relative;
  min-height: 100%;
  font-family: Arial;
`

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7em;

  ${mq['md']} {
    flex-direction: column;
    align-items: center;
  }
`

const HeaderBox = styled.div`
  background: black;
  color: white;
  border-radius: 4px;
  padding: 0.5em 1em;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;

  p {
    font-size: 0.8rem;
    font-weight: normal;
  }
`

const PricingPlanSide = styled.div`
  margin: 1em 0.5em;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 18em;
`

const PricingPlanCenter = styled.div`
  margin: 1em 0.5em;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(246, 93, 95, 1) 0%,
    rgba(223, 43, 125, 1) 100%
  );
  color: white;
  width: 18em;
`

const PricingPlanItems = styled.div`
  margin: 1em 1em 0em 1em;
  font-size: 0.9rem;
  align-items: center;
  flex-grow: 1;

  ul {
    list-style-type: none;
  }
`

const BottomContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;

  p {
    margin: 0em 0em;
    font-weight: bold;
    font-size: 2.5em;
  }

  span {
    font-size: 0.5em;
  }

  h4 {
    font-family: Arial;
    margin: 0;
    text-transform: lowercase;
  }

  h6 {
    margin: 0.5em 0em;
    font-weight: normal;
    font-family: Arial;
  }

  a {
    color: white;
    box-shadow: none;
    cursor: pointer;
  }
`

const ButtonGradient = styled.button`
  min-height: 2.75rem;
  background: linear-gradient(
    180deg,
    rgba(246, 93, 95, 1) 0%,
    rgba(223, 43, 125, 1) 100%
  );
  color: white;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5em 1em;
  width: fit-content;
  margin: 0.5em;

  div {
    display: flex;
    justify-content: center;
  }

  &:disabled {
    cursor: default;
    background: none;
    background-color: #bebebe;
  }

  a {
    color: white;
    box-shadow: none;
  }
`

const ButtonWhite = styled(ButtonGradient)`
  background: white;
  color: black;
  width: fit-content;

  &:disabled {
    color: white;
    background-color: #bebebe;
    cursor: default;
  }
`

const PricingPlans = () => {
  const { user } = useContext(UserContext)
  const { t } = useTranslation('pricingPlans')
  const [selectedSubscription, setSelectedSubscription] = useState('')
  const [isRedirectLoading, setIsRedirectLoading] = useState(false)
  const [subscriptionNameQueryParam] = useQueryParam(
    QUERY_PARAM_SUBSCRIPTION_NAME,
    StringParam,
  )

  const [
    getSubscriptionInfo,
    {
      data: getSubscriptionInfoData,
      loading: getSubscriptionInfoLoading,
      error: getSubscriptionInfoError,
    },
  ] = useLazyQuery(GET_SUBSCRIPTION_INFO)

  const [
    createSubscriptionSession,
    {
      data: createSubscriptionSessionData,
      loading: createSubscriptionSessionLoading,
      error: createSubscriptionSessionError,
    },
  ] = useMutation(CREATE_SUBSCRIPTION_SESSION)

  useEffect(() => {
    const fetchGetSubscriptionInfo = async () => {
      await getSubscriptionInfo({
        variables: { uid: user.uid },
      })
    }

    if (
      user &&
      user.uid &&
      !getSubscriptionInfoLoading &&
      !getSubscriptionInfoData
    ) {
      fetchGetSubscriptionInfo()
    }
  }, [
    user,
    getSubscriptionInfo,
    getSubscriptionInfoLoading,
    getSubscriptionInfoData,
  ])

  // navigate the user to the Stripe Checkout Session
  useEffect(() => {
    const redirectToStripeCheckout = async () => {
      const stripe = await stripePromise
      const { error } = await stripe.redirectToCheckout({
        sessionId: createSubscriptionSessionData.createSubscriptionSession,
      })

      if (error) {
        console.error(error)
        notifyError()
      }
    }

    if (
      user &&
      createSubscriptionSessionData &&
      createSubscriptionSessionData.createSubscriptionSession
    ) {
      redirectToStripeCheckout()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [user, createSubscriptionSessionData])

  // create a stripe subscription session using the stripe price nickname if user
  // has no active subscription
  useEffect(() => {
    const fetchSubscriptionSession = async () => {
      await createSubscriptionSession({
        variables: {
          stripePriceNickName: desanitzeQueryParam(subscriptionNameQueryParam),
        },
      })
    }

    if (
      subscriptionNameQueryParam !== undefined &&
      user &&
      getSubscriptionInfoData &&
      getSubscriptionInfoData.getSubscriptionInfo &&
      getSubscriptionInfoData.getSubscriptionInfo.tier === TIER_FREE
    ) {
      // set button to show loading animation during fetching
      setSelectedSubscription(desanitzeQueryParam(subscriptionNameQueryParam))
      setIsRedirectLoading(true)

      fetchSubscriptionSession()
    }
  }, [
    user,
    createSubscriptionSession,
    getSubscriptionInfoData,
    subscriptionNameQueryParam,
  ])

  useEffect(() => {
    if (createSubscriptionSessionError || getSubscriptionInfoError) {
      notifyError()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [createSubscriptionSessionError, getSubscriptionInfoError])

  const SpinnerLight = () => (
    <Loader type="Oval" color="#FFF" height={28} width={20} timeout={40000} />
  )

  const notifyError = useCallback(() => toast.error(t('error')), [t])

  // we need to remove spaces from query param
  const sanitzeQueryParam = (paramArg) => {
    return paramArg.split(' ').join('-')
  }

  // re-add the space we removed when we originally sanitized the query param
  const desanitzeQueryParam = (paramArg) => {
    return paramArg.split('-').join(' ')
  }

  // only allow Stripe Checkout flow when user is authenticated
  const onClickSubscription = async (stripePriceNickName) => {
    setSelectedSubscription(stripePriceNickName)

    if (
      user &&
      getSubscriptionInfoData &&
      getSubscriptionInfoData.getSubscriptionInfo &&
      getSubscriptionInfoData.getSubscriptionInfo.tier === TIER_FREE
    ) {
      await createSubscriptionSession({
        variables: { stripePriceNickName },
      })
    } else if (!user) {
      setIsRedirectLoading(true)

      const sanitzedQueryParam = sanitzeQueryParam(stripePriceNickName)

      navigate(
        `${process.env.GATSBY_SITE_SIGN_IN_PATH}?${QUERY_PARAM_SUBSCRIPTION_NAME}=${sanitzedQueryParam}`,
      )
    }
  }

  const onClickContactSupport = () => {
    if (typeof window !== 'undefined') {
      window.location.assign(`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`)
    }
  }

  const is3DmeetPremiumMonthlyButtonLoading =
    selectedSubscription === MEET3D_PREMIUM_MONTHLY_NAME &&
    (createSubscriptionSessionLoading || isRedirectLoading)
  const is3DmeetPremiumAnnuallyButtonLoading =
    selectedSubscription === MEET3D_PREMIUM_ANNUALLY_NAME &&
    (createSubscriptionSessionLoading || isRedirectLoading)
  const isPurchaseSubscriptionButtonDisabled =
    getSubscriptionInfoLoading ||
    createSubscriptionSessionLoading ||
    (getSubscriptionInfoData &&
      getSubscriptionInfoData.getSubscriptionInfo &&
      (getSubscriptionInfoData.getSubscriptionInfo.tier === TIER_PRO ||
        getSubscriptionInfoData.getSubscriptionInfo.tier === TIER_PREMIUM))

  const isPremium =
    getSubscriptionInfoData &&
    getSubscriptionInfoData.getSubscriptionInfo &&
    getSubscriptionInfoData.getSubscriptionInfo.tier === TIER_PREMIUM

  const premiumFeaturesRaw = t('tierFeaturesPremium', {
    returnObjects: true,
    virtualWorlds: t('virtualWorlds', {
      worlds: '30+',
      participants: 30,
    }),
  })
  const premiumFeatures = Array.isArray(premiumFeaturesRaw)
    ? premiumFeaturesRaw.map((feature) => <li>{feature}</li>)
    : premiumFeaturesRaw

  const eventFeaturesRaw = t('tierFeaturesEvents', {
    returnObjects: true,
  })
  const eventFeatures = Array.isArray(eventFeaturesRaw)
    ? eventFeaturesRaw.map((feature) => <li>{feature}</li>)
    : eventFeaturesRaw

  return (
    <SectionContainer id="pricing">
      <PlansContainer>
        <PricingPlanSide>
          <HeaderBox>{t('tierPremium')}</HeaderBox>
          <PricingPlanItems>
            <ul>{premiumFeatures}</ul>
          </PricingPlanItems>
          {!isMobilePlatform() && (
            <BottomContainer>
              <p>
                <span>
                  {t('tierMonthPricePremium', { price: '$199' })}{' '}
                  {t('tierAnnualPricePremium', { price: '$1990' })}
                </span>
              </p>

              <h6>{t('tierAnnualSavingsPremium', { discount: '17' })}</h6>
              {isPremium ? (
                <ButtonGradient
                  onClick={() => {
                    navigate(process.env.GATSBY_SITE_MANAGE_ACCOUNT_PATH)
                  }}
                >
                  {t('manage')}
                </ButtonGradient>
              ) : (
                <>
                  <ButtonGradient
                    disabled={
                      isPurchaseSubscriptionButtonDisabled &&
                      selectedSubscription !== MEET3D_PREMIUM_ANNUALLY_NAME
                    }
                    onClick={() =>
                      onClickSubscription(MEET3D_PREMIUM_ANNUALLY_NAME)
                    }
                  >
                    {is3DmeetPremiumAnnuallyButtonLoading ? (
                      <SpinnerLight />
                    ) : (
                      t('buyAnnual')
                    )}
                  </ButtonGradient>
                  <ButtonGradient
                    disabled={
                      isPurchaseSubscriptionButtonDisabled &&
                      selectedSubscription !== MEET3D_PREMIUM_MONTHLY_NAME
                    }
                    onClick={() =>
                      onClickSubscription(MEET3D_PREMIUM_MONTHLY_NAME)
                    }
                  >
                    {is3DmeetPremiumMonthlyButtonLoading ? (
                      <SpinnerLight />
                    ) : (
                      t('buyMonthly')
                    )}
                  </ButtonGradient>
                </>
              )}
            </BottomContainer>
          )}
        </PricingPlanSide>
        <PricingPlanCenter>
          <HeaderBox>{t('tierEvents')}</HeaderBox>
          <PricingPlanItems>
            <ul>{eventFeatures}</ul>
          </PricingPlanItems>
          {!isMobilePlatform() && (
            <BottomContainer>
              <a href={`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`}>
                {`${process.env.GATSBY_SUPPORT_EMAIL}`}
              </a>
              <ButtonWhite onClick={() => onClickContactSupport()}>
                {t('contactSales')}
              </ButtonWhite>
            </BottomContainer>
          )}
        </PricingPlanCenter>
      </PlansContainer>
    </SectionContainer>
  )
}

export default PricingPlans
